export const environment = {
  production: true,
  AppConfigs: {
    env: 'prod',
    appid: 'Mentor',
    rbacUrl: 'https://mentor.cdsvisual.net/api/rbac',
    BackendURL: 'https://mentor.cdsvisual.net/api/mentor',
    studioURL: 'https://mentor.cdsvisual.net/workinstructionstudioreact/?',
    tenant: 'prodcdsmentor.onmicrosoft.com',
    clientId: '1e7d5ea3-51a0-4782-91d6-d87d8a70334b',
    tenantAuthority: 'prodcdsmentor.b2clogin.com',
    b2cScopes: 'https://prodcdsmentor.onmicrosoft.com/1e7d5ea3-51a0-4782-91d6-d87d8a70334b/user.read',
    redirectUrl: 'https://mentor.cdsvisual.net',
    fileManagerApi: 'https://vfm.cdsvisual.net',
    b2cPolicy: 'B2C_1A_SIGNUP-SIGNIN',
    userFormat: 'both',
    solutionName: 'Platform',
    b2cLoginURL: 'https://prodcdsmentor.b2clogin.com',
    amplitudePublicKey: 'fea74b7dc0c468fd6eb2a8bb6b059d12',
    chargebeeSiteName: 'cdsvisualmentor',
    googleAnalyticsTag: 'G-6F67W72EWY',
    regexForEmail: '/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/',
    doverADIDP: '263054161e7248bd8f973aa825946dc2',
    internalUsers: ['dovercorp.com'],
    clientDataEncryptionHash: 'cVBPNGVybTMxNkZOWEF6bHpJMDBVWVFGNzMyY0hFRmc=',
    internalLinkIdentifier: 'https://vfm.cdsvisual.net:444',
    govServer: false,
    cdsInternalApiKey: 'znL0wd1jbH7xfMg2hQmD21SErVkiBb19aT9mNnof',
    fileUploadLimit: {
      customPpe: 5,
      cad: 250,
      media: 2000,
      procedureReferenceDoc: 300,
      companyLogo: 5
    },
  },
};